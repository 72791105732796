import * as _ from 'lodash';
export class NotificationsServiceBase {
    constructor(signalr, logger, $rootScope, $q, __env, authService) {
        this.signalr = signalr;
        this.logger = logger;
        this.$rootScope = $rootScope;
        this.$q = $q;
        this.__env = __env;
        this.authService = authService;
        this.pendingCalls = [];
        this.groups = [];
        this.hubRegistrations = {};
        this.onUserAddedToActivity = _.noop;
        this.onUserRemovedFromActivity = _.noop;
        this.disconnect = _.noop;
        throw new Error('Not implemented; Use Vue version');
    }
    static get ConnectionId() {
        return NotificationsServiceBase.connectionId;
    }
    addUserToGroup(groupName, isActivity) {
        throw new Error(`Not implemented; Use Vue version. Params: ${groupName}, ${isActivity}`);
    }
    removeUserFromGroup(groupName, isActivity) {
        throw new Error(`Not implemented; Use Vue version. Params: ${groupName}, ${isActivity}`);
    }
    onLogout() {
        this.disconnect();
    }
    createEvent(hubName, signalrEvent) {
        this.$q.defer();
        throw new Error(`Not implemented; Use Vue version. Params: ${hubName}, ${signalrEvent}`);
    }
    createReplayEvent(hubName, signalrEvent) {
        this.$q.defer();
        throw new Error(`Not implemented; Use Vue version. Params: ${hubName}, ${signalrEvent}`);
    }
    rejectNotConnected() {
        return this.$q.reject('Signalr not connected');
    }
    forwardCallToHub(hubName, method, ...args) {
        const deffered = this.$q.defer();
        deffered.reject(`Not implemented; Use Vue version. Params: ${hubName}, ${method}, ${args}`);
        return deffered.promise;
    }
    connect() {
        throw new Error('Not implemented; Use Vue version');
    }
}
