import {
  Segmentation2,
  MissingSegmentationsRequest,
  ArchivedSegmentationsRequest,
  CreateActivityResponse,
  CreateActivityRequest,
  GetSurveyLinkResponse,
  ChannelType,
  CreateRespondentAndReturnScreenerSurveyLinkRequest,
  SyncActivitiesQuestionnaireRequest,
  UpdateActivityConfigRequest,
  UpdateActivityInfoRequest,
  UpdateActivitySegmentationRequest,
  PublishActivityRequest,
  CompleteScreenerActivityResponse,
  CompleteScreenerActivityRequest,
  PinActivityRequest,
  UpdateActivityQualSpecifyResponse,
  UpdateActivityQualSpecifyRequest,
  UpdateActivityQualSpecifyForDividedTypeResponse,
  UpdateActivityQualSpecifyForDividedTypeRequest,
  UpdateActivityQualBuildRequest,
  UpdateActivityQualPublishRequest,
  ResetActivityRequest,
  GetRespondentRequest,
} from '../../../models/command';
import{ httpService } from '../../../../src/config';
import { DateTime } from 'luxon';
import { AxiosRequestConfig } from 'axios';

export const getMissingSegmentations = (request: MissingSegmentationsRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<Segmentation2[]>(
    '/ActivityService/GetMissingSegmentations',
    request,
    undefined,
    config);

export const getArchivedSegmentations = (request: ArchivedSegmentationsRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<Segmentation2[]>(
    '/ActivityService/GetArchivedSegmentations',
    request,
    undefined,
    config);

export const createActivity = (request: CreateActivityRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<CreateActivityResponse>(
    '/ActivityService/CreateActivity',
    request,
    undefined,
    config);

export const getProfileSurveyLink = (activityGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<GetSurveyLinkResponse>(
    '/ActivityService/GetProfileSurveyLink',
    undefined,
    { activityGuid },
    config);

export const getResearchSurveyLink = (sampleGuid: string, isPreview: boolean, channel?: ChannelType, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<GetSurveyLinkResponse>(
    '/ActivityService/GetResearchSurveyLink',
    undefined,
    { sampleGuid, isPreview, channel },
    config);

export const getResearchSurveyLinkForActivity = (activityGuid: string, isPreview: boolean, channel?: ChannelType, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<GetSurveyLinkResponse>(
    '/ActivityService/GetResearchSurveyLinkForActivity',
    undefined,
    { activityGuid, isPreview, channel },
    config);

export const getScreenerSurveyLink = (request: CreateRespondentAndReturnScreenerSurveyLinkRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<GetSurveyLinkResponse>(
    '/ActivityService/GetScreenerSurveyLink',
    request,
    undefined,
    config);

export const getTestSurveyLink = (activityGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<GetSurveyLinkResponse>(
    '/ActivityService/GetTestSurveyLink',
    undefined,
    { activityGuid },
    config);

export const prepareSurvey = (activityGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<string>(
    '/ActivityService/PrepareSurvey',
    undefined,
    { activityGuid },
    config);

export const syncActivitiesQuestionnaire = (request: SyncActivitiesQuestionnaireRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<string>(
    '/ActivityService/SyncActivitiesQuestionnaire',
    request,
    undefined,
    config);

export const updateActivityConfig = (request: UpdateActivityConfigRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/ActivityService/UpdateActivityConfig',
    request,
    undefined,
    config);

export const updateActivityInfo = (request: UpdateActivityInfoRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/ActivityService/UpdateActivityInfo',
    request,
    undefined,
    config);

export const updateActivitySegmentations = (request: UpdateActivitySegmentationRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/ActivityService/UpdateActivitySegmentations',
    request,
    undefined,
    config);

export const publishActivity = (request: PublishActivityRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/ActivityService/PublishActivity',
    request,
    undefined,
    config);

export const completeScreenerActivity = (request: CompleteScreenerActivityRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<CompleteScreenerActivityResponse>(
    '/ActivityService/CompleteScreenerActivity',
    request,
    undefined,
    config);

export const toggleActivityPin = (request: PinActivityRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/ActivityService/ToggleActivityPin',
    request,
    undefined,
    config);

export const updateActivityQualSpecify = (request: UpdateActivityQualSpecifyRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<UpdateActivityQualSpecifyResponse>(
    '/ActivityService/UpdateActivityQualSpecify',
    request,
    undefined,
    config);

export const updateActivityQualSpecifyForDividedTypes = (request: UpdateActivityQualSpecifyForDividedTypeRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<UpdateActivityQualSpecifyForDividedTypeResponse>(
    '/ActivityService/UpdateActivityQualSpecifyForDividedTypes',
    request,
    undefined,
    config);

export const updateActivityQualBuild = (request: UpdateActivityQualBuildRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/ActivityService/UpdateActivityQualBuild',
    request,
    undefined,
    config);

export const updateActivityQualPublish = (request: UpdateActivityQualPublishRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/ActivityService/UpdateActivityQualPublish',
    request,
    undefined,
    config);

export const resetActivity = (request: ResetActivityRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<string>(
    '/ActivityService/ResetActivity',
    request,
    undefined,
    config);

export const updateLastSelectedDate = (activityGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/ActivityService/UpdateLastSelectedDate',
    undefined,
    { activityGuid },
    config);

export const deleteActivity = (activityGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/ActivityService/DeleteActivity',
    undefined,
    { activityGuid },
    config);

export const toggleActivityArchive = (activityGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<string[]>(
    '/ActivityService/ToggleActivityArchive',
    undefined,
    { activityGuid },
    config);

export const getRespondentObject = (request: GetRespondentRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<Record<string, string>>(
    '/ActivityService/GetRespondentObject',
    request,
    undefined,
    config);
