import { isFunction, noop, once } from 'lodash-es';
import { convertPascalCasedObjectToCamelCasedObject } from '@ui/common/utils/convert-case';
import { convertDateTimesForObject } from '@ui/common/utils/convert-luxon';

export class EventPubSub<T> {
	constructor(
		public eventName: string,
		private shouldResolveInitialValueOnConnect: boolean = true,
	) {}

	private lastValue?: T;
	private initialValue?: T | Promise<T> | (() => Promise<T>);
	private connection: signalR.HubConnection | undefined;
	private callback: (data: T) => void = noop;

	// Sets the initial value
	// Wil be used for resetSubscriptions
	// Will also be used on the first subscribe if the shouldResolveInitialValueOnConnect property is true
	public startWith(promiseFactoryOrValue: T | Promise<T> | (() => Promise<T>)) {
		this.initialValue = promiseFactoryOrValue;
		return this;
	}

	public subscribe(callback: (data: T) => void, doNotConvert = false) {
		const adaptedCallback = doNotConvert
			? callback
			: (data: T) => {
					data = convertPascalCasedObjectToCamelCasedObject(data) as T;
					data = convertDateTimesForObject(data) as T;
					callback(data);
				};
		this.callback = adaptedCallback;
		this.connection?.on(this.eventName, adaptedCallback);
		return () => this.connection?.off(this.eventName, adaptedCallback);
	}

	public useConnection(connection: signalR.HubConnection) {
		this.connection = connection;
	}

	// Will reset the data to the initial state
	// This method is called on every connect (so the first one, and every reconnect) and resolves the initial value if needed
	public resetSubscriptions() {
		this.lastValue = undefined;
		if (this.shouldResolveInitialValueOnConnect) {
			this.resolveInitialValue();
		}
	}

	private resolve(data: T) {
		if (this.lastValue !== data) {
			this.lastValue = data;
			this.callback(data);
		}
	}

	private resolveInitialValue() {
		// If last value differs from undefined,
		// It means that this isn't the initial resolve so we emit the data that we already had.
		// This can happen if we subscribe multiple times to the same event.
		// The first subscription should set the initial value, but we don't want the second subscription to do that again.
		if (this.lastValue !== undefined) {
			this.resolve(this.lastValue);
		} else if (this.initialValue !== undefined) {
			let init = this.initialValue;
			if (isFunction(init)) {
				init = init();
			}
			Promise.resolve(init).then((data) => {
				this.resolve(data);
			});
		}
	}

	next(data: T) {
		if (this.lastValue !== data || (this.lastValue === undefined && data === undefined)) {
			this.lastValue = data;
		}
	}

	// not used yet, but will be when the Angular Component where it is used will be transfered to Vue
	public toPromise(): Promise<T> {
		if (this.lastValue !== undefined) {
			return Promise.resolve(this.lastValue);
		}
		if (this.initialValue !== undefined) {
			let init = this.initialValue;
			if (isFunction(init)) {
				init = init();
			}
			return Promise.resolve(init);
		}

		return new Promise((resolve) => {
			this.subscribe(
				once((data: T) => {
					Promise.resolve(data).then((d) => {
						resolve(d);
					});
				}),
			);
		});
	}
}
