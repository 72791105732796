import {
  SsoResponse,
  MigrateSquareParticipantsToADLoginRequest,
} from '../../../models/command';
import{ httpService } from '../../../../src/config';
import { DateTime } from 'luxon';
import { AxiosRequestConfig } from 'axios';

export const participantLogin = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<string>(
    '/AuthorizationService/ParticipantLogin',
    undefined,
    undefined,
    config);

export const updateUser = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/AuthorizationService/UpdateUser',
    undefined,
    undefined,
    config);

export const createSquareParticipantForInSitesUser = (squareGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/AuthorizationService/CreateSquareParticipantForInSitesUser',
    undefined,
    { squareGuid },
    config);

export const loginWithSso = (returnUri?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<string>(
    '/AuthorizationService/LoginWithSso',
    undefined,
    { returnUri },
    config);

export const isSsoDomain = (email?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<boolean>(
    '/AuthorizationService/IsSsoDomain',
    undefined,
    { email },
    config);

export const squareParticipantSso = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<SsoResponse>(
    '/AuthorizationService/SquareParticipantSso',
    undefined,
    undefined,
    config);

export const migrateSquareParticipantsToAdLogin = (request: MigrateSquareParticipantsToADLoginRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<boolean>(
    '/AuthorizationService/MigrateSquareParticipantsToADLogin',
    request,
    undefined,
    config);

export const migrateSquareParticipantsToAd = (request: MigrateSquareParticipantsToADLoginRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/AuthorizationService/MigrateSquareParticipantsToAd',
    request,
    undefined,
    config);
