import { NotificationsServiceBase } from './notificationservicebase';
export class NotificationsService extends NotificationsServiceBase {
    constructor(signalr, logger, $rootScope, $q, __env, authService, userService, squareFactory) {
        super(signalr, logger, $rootScope, $q, __env, authService);
        this.userService = userService;
        this.squareFactory = squareFactory;
        throw new Error('Not implemented; Use Vue version');
    }
    init() {
        throw new Error('Not implemented; Use Vue version');
    }
    reConnect() {
        throw new Error('Not implemented; Use Vue version');
    }
    getSignalRToken() {
        return this.authService.getOrRenewSignalRToken();
    }
    ideaStreamSsoLoginFinished(connectionId, redirectUrl) {
        return this.forwardCallToHub('IdeaStream', 'ideaStreamSsoLoginFinished', connectionId, redirectUrl);
    }
    pageVisited(path) {
        return this.forwardCallToHub('PlatformHealth', 'pageVisited', path);
    }
    elementClicked(event) {
        return this.forwardCallToHub('PlatformHealth', 'elementClicked', event.Identifier, event.ChannelType, event.TargetGuid);
    }
    elementViewed(event) {
        return this.forwardCallToHub('PlatformHealth', 'elementViewed', event.Identifier, event.ChannelType, event.TargetGuid);
    }
}
NotificationsService.$inject = ['signalr', 'logger', '$rootScope', '$q', '__env', 'authService', 'userService', 'squareFactory'];
angular
    .module('insitesApp.core')
    .service('notifications', NotificationsService);
