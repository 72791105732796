import { camelCase, forEach, noop, remove } from 'lodash-es';
import * as signalR from '@microsoft/signalr';

import { EventPubSub } from './event-pub-sub';
import { SquareStatus, StimuliUploadState } from '@/common/constants/enums';
import { BUILD_VERSION, SIGNALR_URL } from '@/common/env.config';
import { useAuthenticationStore } from '@/store/authentication-store';

import { AttachmentUploaded, HubRegistrations, StimuliUploaded } from './events.types';
import { PostChanged } from '@ui/components/user-notification-base/user-notification-base.types';
import { ProfileActivityDetailItemResponse } from '@api/models/query';
import { getTokenForSignalR } from '@api/services/query/default/AuthorizationService';
import { RealtimeMessaging } from '@/common/constants/enums';

// #region Events
export interface UserEvent {
	identifier: string;
	channelType: number | null;
	targetGuid: string;
}

interface StimulusTranscriptionStatusChanged {
	stimulusGuid: string;
	status: StimuliUploadState;
	url?: string;
	thumbnailUrl?: string;
	discussionGuid: string;
}

const hubRegistrations: HubRegistrations = [];

export const createEvent = <T>(
	signalrEvent: RealtimeMessaging,
	shouldResolveInitialValueOnSubscribe = true,
): EventPubSub<T> => {
	const pubSub = new EventPubSub<T>(camelCase(RealtimeMessaging[signalrEvent]), shouldResolveInitialValueOnSubscribe);
	hubRegistrations.push(pubSub as EventPubSub<unknown>);
	return pubSub;
};

// Create all events
export const profileActivitiesChangedEvent = createEvent<ProfileActivityDetailItemResponse[]>(
	RealtimeMessaging.ProfileActivitiesChanged,
);
export const progressChangedEvent = createEvent<number>(RealtimeMessaging.ProgressChanged);
export const userNotificationEvent = createEvent<void>(RealtimeMessaging.UserNotification);
export const timeLineChangedEvent = createEvent<void>(RealtimeMessaging.TimeLineChanged);
export const pHealthDummyEvent = createEvent<void>(RealtimeMessaging.PlatformHealthDummy);
export const pagesChangedEvent = createEvent<void>(RealtimeMessaging.PagesChanged);
export const todoActivitiesChangedEvent = createEvent<number>(RealtimeMessaging.TodoActivities);
export const suspendedNotificationEvent = createEvent<void>(RealtimeMessaging.SuspendedNotification);
export const showVideoThumbnailEvent = createEvent<StimuliUploaded>(RealtimeMessaging.ShowVideoThumbnail);
export const showPhotoThumbnailEvent = createEvent<StimuliUploaded>(RealtimeMessaging.ShowPhotoThumbnail);
export const updateAttachmentUrlEvent = createEvent<AttachmentUploaded>(RealtimeMessaging.UpdateAttachmentUrl);
export const discussionActivityCompleteEvent = createEvent<void>(RealtimeMessaging.DiscussionActivityComplete);
export const forumConversationChangedEvent = createEvent<string>(RealtimeMessaging.ForumConversationChanged);
export const replyAddedEvent = createEvent<string>(RealtimeMessaging.ReplyAdded);
export const loungeTopicAdded = createEvent<string>(RealtimeMessaging.LoungeTopicAdded);
export const discussionChangedEvent = createEvent<string>(RealtimeMessaging.DiscussionChanged);
export const discussionNewChangedEvent = createEvent<PostChanged>(RealtimeMessaging.DiscussionNewChanged);
export const userLoggedOutEvent = createEvent<void>(RealtimeMessaging.UserLoggedOut);
export const communicationSampleCompleteEvent = createEvent<string>(RealtimeMessaging.CommunicationSampleComplete);
export const squareStatusChanged = createEvent<{ Status: SquareStatus }>(RealtimeMessaging.SquareStatusChanged);
export const showEngagementCardVideoThumbnailEvent = createEvent<StimuliUploaded>(
	RealtimeMessaging.ShowEngagementCardVideoThumbnail,
);
export const connected = createEvent<boolean>(RealtimeMessaging.SignalrConnected);
export const forcedLogout = createEvent<boolean>(RealtimeMessaging.ForcedLogout);
export const stimulusUploadStatusUpdatedEvent = createEvent<StimulusTranscriptionStatusChanged>(
	RealtimeMessaging.StimulusTranscriptionStatusChanged,
);
// #endregion

// #region Connection
let pendingCalls: Array<() => void> = [];
let disconnect = noop;
let connection: signalR.HubConnection;

export const connect = async () => {
	disconnect();

	const authStore = useAuthenticationStore();
	let qs = `?version=${BUILD_VERSION}`;
	if (authStore.impersonate?.squareParticipantGuid) {
		qs = `${qs}&ImpersonateGuid=${authStore.impersonate.squareParticipantGuid}`;
	}
	const signalrToken = await getTokenForSignalR();

	connection = new signalR.HubConnectionBuilder()
		.withAutomaticReconnect()
		.withUrl(`${SIGNALR_URL}/realtime${qs}`, {
			accessTokenFactory: async () => signalrToken,
		})
		.build();

	let connectInternal = async () => {
		await connection.start();
		connected.next(true);
		forEach(hubRegistrations, (reg) => {
			reg.useConnection(connection);
			reg.resetSubscriptions();
		});

		// restore groups
		for (const groupName of groups) {
			addUserToGroup(groupName);
		}

		for (const pending of pendingCalls) {
			pending();
		}
		pendingCalls = [];
	};
	await connectInternal();

	disconnect = () => {
		forEach(hubRegistrations, (reg) => connection.off(reg.eventName));
		pendingCalls = [];
		connection.stop();
		connectInternal = () => Promise.resolve();
		disconnect = noop;
	};
};

export const getConnectionId = () => connection.connectionId;
// #endregion

// #region Event handling
const forwardCallToHub = (method: string, ...args: unknown[]) => {
	const signalrCall = () => {
		connection?.invoke(method, ...args);
	};

	// if the connection is down, keep the call in a queue
	// unfortunately we lose the exact timestamp
	if (connection?.state !== signalR.HubConnectionState.Connected) {
		pendingCalls.push(signalrCall);
	} else {
		signalrCall();
	}
};

export const elementClicked = (event: UserEvent) =>
	sendEvent('elementClicked', event.identifier, event.channelType, event.targetGuid);

export const elementViewed = (event: UserEvent) =>
	sendEvent('elementViewed', event.identifier, event.channelType, event.targetGuid);

export const pageVisited = (path: string) => sendEvent('pageVisited', path);

const sendEvent = (methodName: string, ...args: unknown[]) => {
	const forwardCall = () => {
		forwardCallToHub(methodName, ...args);
	};

	if (!connection?.connectionId) {
		pendingCalls.push(forwardCall);
		return;
	}

	forwardCall();
};
// #endregion

// #region Groups
const groups: string[] = [];
const onUserAddedToActivity: (groupName: string) => void = noop;
const onUserRemovedFromActivity: (groupName: string) => void = noop;

export const addUserToGroup = (groupName: string, isActivity?: boolean) => {
	const forwardCall = () => {
		forwardCallToHub('addUserToGroup', groupName);
		if (!groups.includes(groupName)) {
			groups.push(groupName);
		}
	};

	if (!connection?.connectionId) {
		pendingCalls.push(forwardCall);
	} else {
		forwardCall();
	}

	if (isActivity) {
		onUserAddedToActivity(groupName);
	}
};

export const removeUserFromGroup = (groupName: string, isActivity?: boolean) => {
	const forwardCall = () => {
		forwardCallToHub('removeUserFromGroup', groupName);
		remove(groups, (item) => item === groupName);
	};

	if (!connection?.connectionId) {
		pendingCalls.push(forwardCall);
	} else {
		forwardCall();
	}

	if (isActivity) {
		onUserRemovedFromActivity(groupName);
	}
};
// #endregion
