import {
	addUserToGroup,
	connect,
	pagesChangedEvent,
	pHealthDummyEvent,
	profileActivitiesChangedEvent,
	progressChangedEvent,
	squareStatusChanged,
	suspendedNotificationEvent,
	timeLineChangedEvent,
	todoActivitiesChangedEvent,
	userLoggedOutEvent,
	userNotificationEvent,
	forcedLogout,
	showEngagementCardVideoThumbnailEvent,
	forumConversationChangedEvent,
	replyAddedEvent,
	loungeTopicAdded,
	communicationSampleCompleteEvent,
} from '@/common/utils/events';
import { defineStore } from 'pinia';
import { listParticipantProfileActivities, listParticipantProgress } from '@api/services/query/default/ActivityService';
import { usePagesStore } from '@/store/pages-store';
import { useAuthenticationStore } from '@/store/authentication-store';
import { useSquareStore } from '@/store/square-store';
import { DateTime } from 'luxon';
import { getUserNotificationsForConsumerIf } from '@api/services/query/default/NotificationService';
import { isFeatureEnabledForSquare } from '@/services/feature-service';
import { Feature, NotificationType } from '@/common/constants/enums';
import { RouteNames } from '@/router/routes/route-names.enum';
import { RouteLocationNormalized } from 'vue-router';
import { getTodoNotificationsCount } from '@api/services/query/default/SquareService';
import { ProfileActivityDetailItemResponse, UserNotificationResponse } from '@api/models/query';
import { markActivityNotificationsRead, markNotificationRead } from '@api/services/command/default/NotificationService';
import { Dialog } from 'quasar';
import AdMigrationPage from '@/pages/ad-migration-page.vue';

export const useNotificationsStore = defineStore('notifications', {
	state: () => ({
		profileActivities: [] as Array<ProfileActivityDetailItemResponse & { surveyUrl?: string }>,
		progress: null as number | null,
		todoActivities: null as number | null,
		userNotifications: [] as UserNotificationResponse[],
		pHealthDummyEvent: null as DateTime | null,
		timelineChanged: null as DateTime | null,
		VideoThumbnailChanged: null as DateTime | null,
		forumConversationChanged: null as DateTime | null,
		replyAdded: null as DateTime | null,
		loungeTopicAdded: null as DateTime | null,
		loginCallback: false,
	}),

	actions: {
		async init(route: RouteLocationNormalized) {
			// If the participant isn't logged in yet, we want to connect on login
			const authStore = useAuthenticationStore();

			if (!authStore.isAuthorized && !this.$state.loginCallback) {
				authStore.loginFunctions.push(async () => {
					await this.init(route);
				});
				this.$state.loginCallback = true;
				return;
			}

			// Don't init the notification store for mobile users (unless for the profile page)
			if (authStore.isVersionedMobileAppLogin && !route.name?.toString().includes(RouteNames.Profile)) {
				return;
			}

			// Connect to SignalR
			await connect();

			// Add initial values and subscribe to all events and provide callbacks with what has to change if new values come in.
			profileActivitiesChangedEvent.startWith(async () => {
				if (useSquareStore().info.ssoEnabled) {
					return [];
				}
				return (
					(await listParticipantProfileActivities(useAuthenticationStore().tokenInfo?.squareParticipantGuid || ''))
						.list || []
				);
			});
			profileActivitiesChangedEvent.subscribe(async (profileActivities) => {
				this.userNotifications = await getUserNotificationsForConsumerIf();
				this.profileActivities = profileActivities;
			});
			progressChangedEvent.startWith(
				async () => await listParticipantProgress(useAuthenticationStore().tokenInfo?.squareParticipantGuid || ''),
			);
			progressChangedEvent.subscribe((progress) => (this.progress = progress));

			// We've initialized enough for mobile users
			if (authStore.isVersionedMobileAppLogin) {
				return;
			}

			todoActivitiesChangedEvent.startWith(async () => await getTodoNotificationsCount());
			todoActivitiesChangedEvent.subscribe((todoActivities) => (this.todoActivities = todoActivities));

			timeLineChangedEvent.subscribe(() => (this.timelineChanged = DateTime.utc()));
			showEngagementCardVideoThumbnailEvent.subscribe(() => (this.VideoThumbnailChanged = DateTime.utc()));
			forumConversationChangedEvent.subscribe(() => (this.forumConversationChanged = DateTime.utc()));
			replyAddedEvent.subscribe(() => (this.replyAdded = DateTime.utc()));
			loungeTopicAdded.subscribe(() => (this.loungeTopicAdded = DateTime.utc()));
			userNotificationEvent.subscribe(async () => {
				this.userNotifications = await getUserNotificationsForConsumerIf();
			});
			pHealthDummyEvent.subscribe(() => (this.pHealthDummyEvent = DateTime.utc())); // in order to get connected/ disconnect calls
			pagesChangedEvent.subscribe(async () => await usePagesStore().fetchConsumerPages(true));
			suspendedNotificationEvent.subscribe(async () => await useAuthenticationStore().signout());
			userLoggedOutEvent.subscribe(async () => await useAuthenticationStore().signout());
			squareStatusChanged.subscribe((data) => (useSquareStore().info.status = data.Status));
			forcedLogout.subscribe(async () => await useAuthenticationStore().signout());
			communicationSampleCompleteEvent.subscribe(async (sampleCommunicationGuid: string) => {
				if (!authStore.isAuthorized || authStore.isAdUser) {
					return;
				}
				const squareStore = useSquareStore();
				if (!squareStore.info.adLogin || !squareStore.info.adMigration) {
					return;
				}
				if (sampleCommunicationGuid) {
					// we need to go to AD migration whenever a participant completes an activity
					setTimeout(
						() =>
							Dialog.create({
								component: AdMigrationPage,
							}),
						1000,
					);
				}
			});

			// Add the user to the group of the current Square
			addUserToGroup(useSquareStore().info.guid);

			// Get the initial value for userNotifications and set the notification number for incentives if enabled
			this.userNotifications = await getUserNotificationsForConsumerIf();
		},

		async markNotificationRead(
			notificationGuid: string,
			relatedNotificationGuids: string[] | undefined,
			refetchNotifications = true,
		) {
			try {
				await markNotificationRead({
					notificationGuid,
					relatedNotificationGuids,
				});
			} finally {
				if (refetchNotifications) {
					this.userNotifications = await getUserNotificationsForConsumerIf();
				}
			}
		},

		async markActivityNotificationsRead(activityGuid: string) {
			try {
				await markActivityNotificationsRead(activityGuid);
			} finally {
				this.userNotifications = await getUserNotificationsForConsumerIf();
			}
		},
	},
	getters: {
		totalCredits: () => {
			const isSavingUpEnabled = isFeatureEnabledForSquare(Feature.SavingUpRewards);
			const userNotifications = useNotificationsStore().userNotifications;
			if (!isSavingUpEnabled) {
				return;
			}

			let creditsCount = 0;
			const savingUpRewardNotification = userNotifications.find(
				(item) => item.notificationType === NotificationType.SavingUpRewardsEarned,
			);

			if (savingUpRewardNotification) {
				creditsCount = 1;
			} else {
				const moreSavingUpRewardNotification = userNotifications.find(
					(item) => item.notificationType === NotificationType.MoreSavingUpRewardsEarned,
				);
				if (moreSavingUpRewardNotification) {
					creditsCount = moreSavingUpRewardNotification.relatedNotificationGuids?.length ?? 0;
				}
			}

			return creditsCount;
		},
	},
});
