import { NotificationsServiceBase } from '../services/notificationservicebase';
export class HttpService {
    constructor($http, Upload, __env) {
        this.$http = $http;
        this.Upload = Upload;
        this.__env = __env;
        this.combineURLPaths = (path1, path2) => path2
            ? `${path1.replace(/\/+$/, '')}/${path2.replace(/^\/+/, '')}`
            : path1;
        $http.defaults.useXDomain = true;
        delete $http.defaults.headers.common['X-Requested-With'];
        this.initAngularInVueImpersonating();
    }
    get NgHttp() {
        return this.$http;
    }
    put(data) {
        return this.send(Object.assign(Object.assign({}, data), { method: 'PUT', url: this.combineURLPaths(this.__env.commandApiUrl, data.url) }));
    }
    delete(data) {
        return this.send(Object.assign(Object.assign({}, data), { method: 'DELETE', url: this.combineURLPaths(this.__env.commandApiUrl, data.url) }));
    }
    post(data) {
        return this.send(Object.assign(Object.assign({}, data), { method: 'POST', url: this.combineURLPaths(this.__env.commandApiUrl, data.url) }));
    }
    get(data) {
        return this.send(Object.assign(Object.assign({}, data), { method: 'GET', url: this.combineURLPaths(this.__env.queryApiUrl, data.url) }));
    }
    exists(url) {
        return this.$http.get(url);
    }
    upload(data) {
        return this.Upload.upload(Object.assign(Object.assign({}, data), { headers: this.getHeaders(data.headers), url: this.combineURLPaths(this.__env.commandApiUrl, data.url) }));
    }
    send(data) {
        data.headers = this.getHeaders(data.headers);
        return this.$http(data);
    }
    useToken(token) {
        this.authToken = token;
    }
    useImpersonate(impersonate) {
        this.impersonate = impersonate;
    }
    getHeaders(headers) {
        if (!headers) {
            headers = {};
        }
        if (this.authToken) {
            headers.Authorization = `Bearer ${this.authToken}`;
        }
        if (this.impersonate && this.impersonate.squareParticipantGuid) {
            const impersonate = {
                ImpersonateGuid: this.impersonate.squareParticipantGuid,
            };
            headers.ImpersonateGuid = impersonate.ImpersonateGuid;
        }
        if (NotificationsServiceBase.ConnectionId) {
            headers.ConnectionId = NotificationsServiceBase.ConnectionId;
        }
        headers.BuildVersion = this.__env.buildVersion;
        return headers;
    }
    initAngularInVueImpersonating() {
        const jsImpersonate = window.localStorage.getItem('Impersonating') || window.sessionStorage.getItem('Impersonating');
        if (jsImpersonate) {
            this.impersonate = JSON.parse(jsImpersonate);
        }
    }
}
HttpService.$inject = ['$http', 'Upload', '__env'];
